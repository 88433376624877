import { _ } from "vue-underscore";
import Vue from "vue";
export default {
  data: () => ({
    landingCities: {
      id: 0,
      url_slug: "",
      title: "",
      meta_description: "",
      meta_title: "",
      meta_keywords: "",
      head_scripts: "",
      body_scripts: "",
      logoImage: "",
      logo_url: "",
    },
    loading: false,
    webUrl: process.env.VUE_APP_WEB_URL,
    cities: [],
    images: [],
    files: [],
    error: "",
    backUrl: "/landing-city",
  }),
  methods: {
    // Reset Form
    resetForm() {
      this.$refs.observer.reset();
      this.landingCities = {
        id: 0,
        title: "",
        meta_description: "",
        meta_title: "",
        url_slug: "",
        head_scripts: "",
        body_scripts: "",
      };
    },
    // Add and Update Function call to submit button
    validateLandingCities() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
      this.landingCities.id > 0 ? this.update() : this.add();
    },

    //Image Change
    onFileChange(e) {
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.images.push(selectedFiles[i]);
      }

      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener(
          "load",
          function () {
            this.$refs["image" + parseInt(i)][0].src = reader.result;
          }.bind(this),
          false
        ); //add event listener

        reader.readAsDataURL(this.images[i]);
      }
    },

    //Remove Image in Storage
    removeImage(id) {
      let _vm = this;
      this.$dialog
        .confirm("Are you sure you want to delete this record?")
        .then(function () {
          _vm.axios
            .delete("landing-cities-image-delete/" + id)
            .then(function () {
              _vm.getDetail();
            })
            .catch(function (error) {
            });
        })
        .catch(function (error) {
        });
    },

    //Update Landing City Deatils and Multiple Images
    update() {
      let _vm = this;
      let fd = new FormData();
      if (_vm.landingCities.logoImage) {
        fd.append("logoImage", _vm.landingCities.logoImage);
      }
      if (this.images && this.images.length > 0) {
        this.images.forEach((element) => {
          fd.append("multipleImages[]", element);
        });
      }
      Object.keys(_vm.landingCities).forEach((key) => {
        fd.append(key, _vm.landingCities[key]);
      });
      fd.append("_method", "PATCH");
      this.axios
        .post("/landing-cities/" + _vm.landingCities.id, fd, {
          ...this.landingCities,
        })
        .then(function (response) {
          _vm.landingCities = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },

    //Get Image On Update Time
    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        _vm.landingCities.logoImage = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    // Store Landing City Deatils and Multiple Images
    add() {
      let _vm = this;
      let fd = new FormData();
      if (_vm.landingCities.logoImage) {
        fd.append("logoImage", _vm.landingCities.logoImage);
      }

      if (this.images && this.images.length > 0) {
        this.images.forEach((element) => {
          fd.append("multipleImages[]", element);
        });
      }
      Object.keys(_vm.landingCities).forEach((key) => {
        fd.append(key, _vm.landingCities[key]);
      });
      this.axios.post("/landing-cities", fd).then(function (response) {
        _vm.$router.push(_vm.backUrl);
      });
    },
    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },

    //Fetch Data On Update
    getDetail() {
      let _vm = this;
      this.axios
        .get("/landing-cities/" + this.$route.params.id)
        .then(function (response) {
          _vm.landingCities = response.data.data;
          if (
            _vm.landingCities.full_image_url &&
            _vm.landingCities.full_image_url.length > 0
          ) {
            $("#imagePreview").css(
              "background-image",
              "url(" +
                _vm.landingCities.full_image_url +
                "?date=" +
                Date.now() +
                ")"
            );
            $("#imagePreview").hide();
            $("#imagePreview").fadeIn(650);
          }
        })
        .catch(function () {});
    },

    //Get Cities On Airport Module
    getCities() {
      let _vm = this;
      this.axios
        .get("/city-by-city-code-list", _vm.cities)
        .then(function (response) {
          _vm.cities = response.data.data;
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail();
          }
        })
        .catch(function () {});
    },
    updateUrl(event){
      if(event.code === 'Space'){
        if(this.landingCities.url_slug.length>1 && this.landingCities.url_slug[this.landingCities.url_slug.length-2]==='-'){
          this.landingCities.url_slug =  event.target.value.replace(/\s{1,}/g, '');
        }else{
         this.landingCities.url_slug =  event.target.value.replace(/\s{1,}/g, '-');
        }
      }

    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
   
    this.getCities();
  },
};
